<template>
  <v-container fluid class="px-8">
    <v-toolbar height="50" class="mb-4 mt-2" elevation="4" rounded>
      <TableMenu />
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="6">
        <special-indicators></special-indicators>
      </v-col>

      <v-col cols="12" md="6">
        <special-travel-needs></special-travel-needs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpecialIndicators from './SpecialIndicators.vue';
import SpecialTravelNeeds from './SpecialTravelNeeds.vue';
import TableMenu from './Menu.vue';

export default {
  mixins: [],
  components: { SpecialIndicators, SpecialTravelNeeds, TableMenu },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped></style>
